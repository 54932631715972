import { Atom, Cmp, SHARED_UTILS } from ":mods";
import { TopElementViewProps, TopNavItemGroup } from "../model";
import { For, Show, splitProps } from "solid-js";
// import { actions } from "../../routes/store";
// import { actions } from "../store";

export function TopElement(props: TopElementViewProps) {
  const [local, others] = splitProps(props, ["items", "children", "itemsClass", "base"]);
  return (
    <Atom.Foldable.Accordion {...others} title={local.children} activeClass={`${others.activeClass ?? ""}`}>
      <Show when={local.items !== undefined && local.items !== null}>
        <section class={`!absolute w-full h-fit !z-100 mt-5 py-3 px-3 text-0.7rem ${local.itemsClass ?? ""}`}>
          <Show when={Array.isArray(local.items)} fallback={local.items as JSX.Element}>
            <For each={local.items as TopNavItemGroup}>
              {(item) => {
                if (typeof item === "function") {
                  return (
                    <>
                      {item({
                        actions: {
                          ...Cmp.Alert.actions,
                          ...Cmp.Popup.actions,
                          ...Atom.Route.actions,
                        },
                        base: local.base,
                        classes: { anchor: "anchor" },
                      })}
                    </>
                  );
                }
                let to = undefined;
                if (item.to !== undefined && item.to !== null) {
                  to = SHARED_UTILS.mergeRouteHref({ base: local.base }, item.to);
                }
                console.log("top element :: ", to);
                return (
                  <Atom.Route.Anchor
                    {...item}
                    to={to}
                    class={`anchor flex flex-row items-center gap-x-2 ${item.class ?? ""} `}
                    activeClass={`active`}
                  />
                );
              }}
            </For>
          </Show>
        </section>
      </Show>
    </Atom.Foldable.Accordion>
  );
}
